import React, { useState, useEffect, useRef, Suspense } from "react";
import styled from "styled-components";
import { Context } from "./util/context.js";
import { useDarkMode } from "./DarkMode/UseDarkMode.jsx";
import { lightTheme, darkTheme } from "./DarkMode/Themes.jsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faUser,
  faShirt,
  faHouse,
  faCloud,
  faRobot,
  faScroll,
  faFingerprint,
  faWindowRestore,
  faCloudArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import HomePage from "./HomePage.jsx";
import CartModal from "./CartModal.jsx";
import Product from "./Product.jsx";
import Checkout from "./Checkout.jsx";
import IFrame from "./IFrame.jsx";
import apiCall from './util/apiCall.js';

window.React = React;
Window.sessionStorage = { cart: [], qty: 0 };

const App = (props) => {
  let localCart = JSON.parse(localStorage.getItem("Cart")) || [];
  let localQty = localCart.length
  const [theme, themeToggler] = useDarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;

  const [view, setView] = useState({ name: "Product", viewProps: {} });
  const [cartQty, setCartQty] = useState(localQty);
  const [cart, setCart] = useState(localCart);
  const [cartModal, setCartModal] = useState(false);
  const [isFrameOpen, setIsFrameOpen] = useState(false);
  const reviewsRef = useRef();
  const scrollRef = useRef();
  const [id, setId] = useState(40344);

  const urlParams = new URLSearchParams(window.location.search);
  const [requestApi, setRequestApi] = useState(urlParams.get('api') || 'fetch');
  const handleChangeRadioButton = (changeEvent) => {
    const value = changeEvent.target.value;
    setRequestApi(value);
  }
  useEffect(() => {
    localStorage.setItem("Cart", JSON.stringify(cart))
    localCart = JSON.parse(localStorage.getItem("Cart"))
    localQty = localCart.length
    setCartQty(localQty)
  }, [cart])

  const changeView = (name, someProps = {}) => {
    return (moreProps = {}) => {
      setView({ name, viewProps: { ...someProps, ...moreProps } });
    };
  };

  useEffect(() => {
    renderView();
  }, [view]);

  const renderView = () => {
    switch (view.name) {
      case "Product":
        return (
          <Product
            themeMode={themeMode}
            theme={theme}
            themeToggler={themeToggler}
            cart={cart}
            setCart={setCart}
            cartQty={cartQty}
            setCartQty={setCartQty}
            reviewsRef={reviewsRef}
            id={id}
            setId={setId}
            scrollRef={scrollRef}
          />
        );

      case "Home":
        return (
          <HomePage
            changeView={changeView}
            setId={setId}
            themeMode={themeMode}
            theme={theme}
            themeToggler={themeToggler}
          />
        );

      case "Checkout":
        return (
          <Checkout
            changeView={changeView}
            setId={setId}
            themeMode={themeMode}
            theme={theme}
            themeToggler={themeToggler}
            cartItems={view.viewProps}
            setCart={setCart}
            cart={cart}
            setCartModal={setCartModal}
            cartQty={cartQty}
            setCartQty={setCartQty}
          />
        );
    }
  };

  return (
    <>
    {/* {
        setInterval(() => {
          console.log("Interval started")
          let newRequest = new XMLHttpRequest();
          newRequest.open("get", "http://localhost:3000/reviews/meta/?product_id=40351");
          newRequest.addEventListener("readystatechange", ()=> {
            console.log("Interval readystatechange");
            if (newRequest.readyState === XMLHttpRequest.DONE) {
                console.log("---Interval XHR request completed");
            }
          })
          newRequest.send();
        }, 10000)
    }
    {
        setTimeout(() => {
          let newRequest = new XMLHttpRequest();
          newRequest.open("get", "http://localhost:3000/reviews/meta/?product_id=40351");
          newRequest.addEventListener("readystatechange", ()=> {
            console.log("Timeout readystatechange");
            if (newRequest.readyState === XMLHttpRequest.DONE) {
                console.log("---Timeout XHR request completed");
            }
          })
          newRequest.send();
        }, 20000)
    }

{
        setInterval(() => {
          fetch('http://localhost:3000/reviews/meta/?product_id=40351')
            .then((res) => res.json())
            .then((res) => console.log("---Timeout FETCH request completed"))
        }, 9000)
    }
    {
        setTimeout(() => {
          fetch('http://localhost:3000/reviews/meta/?product_id=40351')
            .then((res) => res.json())
            .then((res) => console.log("---Timeout FETCH request completed"))
        }, 15000)
    } */}
    <Main>
      <HeaderStyle>
        <NavBar
          style={{
            backgroundColor: `${theme === "light" ? "#edf1f7" : "#3c3d40"}`
          }}
        >
          <h1
            onClick={() => changeView("Home")()}
            style={{ cursor: "pointer", zIndex: 1100 }}
          >
            <FontIcon icon={faHouse} />
            &nbsp; Katana{" "}
          </h1>
          <List>
             <ListItem>
              {" "}
              <FontIcon
                  onClick={() => apiCall(requestApi, `https://api.github.com/users/mralexgray/repos`, { method: 'GET' })}
                  icon={faCloudArrowDown}
                  size="lg"
                  title="not protected"
              />{" "}
            </ListItem>
            <ListItem>
              {" "}
              <FontIcon
                onClick={() => apiCall(requestApi, `/block-cwaf`, { method: 'GET' })}
                icon={faCloud}
                size="lg"
	        title="CWAF block"
              />{" "}
            </ListItem>
            <ListItem>
              {" "}
              <FontIcon
              onClick={() => apiCall(requestApi, `/block-abp`, { method: 'GET' })}
              icon={faRobot}
              size="lg"
	      title="ABP block"
            />{" "}
            </ListItem>
            <ListItem>
              {" "}
              <FontIcon
              onClick={() => apiCall(requestApi, `/js-challenge`, { method: 'GET' })}
              icon={faScroll}
              size="lg"
	      title="JS challenge"
            />{" "}
            </ListItem>
            <ListItem>
              {" "}
              <FontIcon
              onClick={() => apiCall(requestApi, `/force`, { method: 'GET' })}
              icon={faFingerprint}
              size="lg"
	      title="Force Identify"
            />{" "}
            </ListItem>
            <ListItem>
              {" "}
              <FontIcon
              onClick={() => setIsFrameOpen(!isFrameOpen)}
              icon={faWindowRestore}
              size="lg"
              title="Iframes test"
            />{" "}
            </ListItem>

            <ListItem onClick={() => setCartModal(!cartModal)}>
              {" "}
              <FontIcon icon={faCartShopping} size="lg" />
              <CartNum>{cartQty}</CartNum>
            </ListItem>
          </List>
          {cartModal && (
            <Context.Provider value={{requestApi}}>
              <CartModal
                isOpen={cartModal}
                setCart={setCart}
                cart={cart}
                setCartModal={setCartModal}
                cartQty={cartQty}
                setCartQty={setCartQty}
                theme={theme}
                changeView={changeView}
              />
            </Context.Provider>
          )}
        </NavBar>
      </HeaderStyle>
      <RadioButtonsDiv>
      requests API (can also add query param: api=axios/fetch/jquery):
        <label>
          <input type="radio" value="fetch" onChange={handleChangeRadioButton} checked={requestApi === 'fetch'} />
          Fetch
          <input type="radio" value="axios" onChange={handleChangeRadioButton} checked={requestApi === 'axios'} />
          Axios
        </label>
        <label>
          <input type="radio" value="jquery" onChange={handleChangeRadioButton} checked={requestApi === 'jquery'} />
          jQuery
        </label>
        <label>
          <input type="radio" value="xhr_methods" onChange={handleChangeRadioButton} checked={requestApi === 'xhr_methods'} />
          XHR methods
        </label>
        <label>
          <input type="radio" value="xhr_listeners" onChange={handleChangeRadioButton} checked={requestApi === 'xhr_listeners'} />
          XHR listeners
        </label>
	<label>
          <input type="radio" value="ajax_api" onChange={handleChangeRadioButton} checked={requestApi === 'ajax_api'} />
          Ajax API
        </label>
      </RadioButtonsDiv>
      <br />
      <CredentialsDiv>
        Cookies - add query param: credentials=same-origin/include/omit (only for fetch):
      </CredentialsDiv>
      <main>
        <Suspense fallback={<p>Loading...</p>}>
          <Context.Provider value={{requestApi}}>
            {renderView()}
            </Context.Provider>
        </Suspense>
        { isFrameOpen && <IframeContainer>
          <IframeDiv><iframe src='https://elya.new.incaptest.co/login.html'></iframe></IframeDiv>
          <IframeDiv><iframe src='https://elya.new.incaptest.co/simpleHtml.html'></iframe></IframeDiv>
          <IframeDiv><iframe src= 'https://elya.incaptest.co/buttonRedirect.html'></iframe></IframeDiv>
        </IframeContainer> }
      </main>
      </Main>
    </>
  );
};

export default App;

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderStyle = styled.header`
  max-width: 100%;
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 98%;
  float: left;
  top: 0;
  left: 0;
  padding: 0 1em;
  border-bottom: 1px solid #ccc;
  z-index: 1200;
  position: fixed;
  height: 4em;
  @media (max-width: 520px) {
    align-items: baseline;
    justify-content: space-around;
  }
  @media (max-width: 268px) {
    height: 5em;
  }
  @media (max-width: 183px) {
    height: 6em;
  }
`
const List = styled.ul`
  display: flex;
  list-style: none;
  width: 20%;
  justify-content: flex-end;
`;

const ListItem = styled.li`
  margin: 3%;
  cursor: pointer;
  z-index: 0;
`;

const CartNum = styled.span`
  font-size: 0.7rem;
  position: relative;
  bottom: 15px;
  font-weight: bold;
  z-index: 2;
`;

const FontIcon = styled(FontAwesomeIcon)``;

const RadioButtonsDiv = styled.div`
  font-size: 20px;
  z-index: 2000;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
`;

const CredentialsDiv = styled.div`
  font-size: 18px;
  position: absolute;
  top: 35px;
  z-index: 2000;
  left: 0;
  right: 0;
  text-align: center;
`;


const IframeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const IframeDiv = styled.div`
  box-sizing: border-box;
  width: calc(33.33% - 20px);
  margin-bottom: 20px;

  iframe {
    width: 100%;
    height: 400px;
    border: 1px solid #ccc;
  }
`;
