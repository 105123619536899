import axios from 'axios';
import $ from 'jquery';
const abortController = new AbortController();

const apiCall = (apiType, url, options) => {
  const urlParams = new URLSearchParams(window.location.search);
  const credentialsParam = urlParams.get('credentials');
  const credentials = ['include', 'omit'].includes(credentialsParam) ? credentialsParam : 'same-origin';
  const withCredentials = credentialsParam === 'include';
  const isHtmlContent = url.includes('html_content') || url.includes('sync_content');
  const singleOriginParam = urlParams.get('single-origin');

  if (singleOriginParam !== 'true' && (url.includes("/qa/questions") || url.includes("/js-challenge") ||  url.includes("/block-cwaf") || url.includes("/block-abp") || url.includes("/force") || url.includes("/cart") || url.includes('=newest') || url.includes('/helpful') || url.includes('/answers/?count=1000'))) {
	  const domain = window.location.host === 'elya.spa.incaptest.co' ? 'elya2.spa.incaptest.co' : 'elya.new.incaptest.co';
	  url = `https://${domain}` + url;
  }


  if (apiType === 'axios') {
    return (
      axios({url, signal: abortController.signal, ...options, withCredentials })
    );
  } else if (apiType === 'fetch') {
    const { data, ...rest } = options;
    return (
      fetch(url, { 
        ...rest,
        body: JSON.stringify(data),
        headers: new Headers({ ...options.headers, 'Accept': 'application/json', 'Content-Type': 'application/json' }),
        signal: abortController.signal,
	...(credentials && { credentials })
      })
      .then((response) => (['POST'].includes(options.method) || isHtmlContent ? response.text() : response.json()))
      .then((res) => ({ data: res }))
    );
  } else if (apiType === 'jquery') {
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        dataType: isHtmlContent ? 'html' : 'json',
        ...options,
        ...(options.data && { contentType: false, processData: false }),
        xhrFields: {
          withCredentials
        },
	success: (data) => {
          resolve({data});
        },
        error: (error) => {
          reject({error})
        },
      })
    });
  } else if (apiType === 'xhr_methods') {
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'json';
      xhr.onload = function (event) {
        const res = xhr;
        console.log('xhr_methods onload', "xhr:", xhr, "res:", res, "event:", event);
        if (res.status >= 200 && res.status < 300) {
          resolve({ data : res.response });
        } else {
          reject({
            status: res.status,
            statusText: res.statusText
          });
        }
      };
      xhr.onerror = function () {
        console.log("xhr_methods error", this)
        reject({
          status: this.status,
          statusText: this.statusText,
          xhr
        });
      };
      xhr.open(options.method, url);
      xhr.withCredentials = withCredentials;
      xhr.onloadstart = (event) => {
        console.log('xhr_methods onloadstart', 'event:', event);
      };
      xhr.onloadend = (event) => {
        console.log('xhr_methods onloadend', 'event:', event);
      };
      xhr.setRequestHeader('Content-Type', 'application/json');
      const body = (options.method !== 'GET' && !options.data) ? null : JSON.stringify(options.data);
      xhr.onreadystatechange = function (event) {
        console.log("xhr_methods onreadystatechange", this);
      };
      xhr.send(body);
    });
  } else if (apiType === 'xhr_listeners') {
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.addEventListener('loadstart', function() {
        console.log('xhr_listeners loadstart1: ', 'readyState =', this.readyState)
      });
      xhr.addEventListener('loadend', function() {
        console.log('xhr_listeners loadend1: ', 'readyState =', this.readyState)
      });
      xhr.addEventListener('load', function(event) {
        console.log('xhr_listeners load1: ', 'readyState =', this.readyState, 'event=', event);
      });

      xhr.open(options.method, url);
      xhr.withCredentials = withCredentials;
      xhr.addEventListener('load', function(event) {
        console.log('xhr_listeners load2: ', 'readyState =', this.readyState, 'event=', event);
      });
      xhr.onreadystatechange = function() {
        const res = this;
        console.log("xhr_listeners onreadystatechange, readyState=", res.readyState);
        if (res.readyState !== 4) return;
        if (res.status >= 200 && res.status < 300) {
          isHtmlContent ? resolve({data: res.responseText}) : resolve({ data : JSON.parse(res.response) });
        } else {
          reject({
            status: res.status,
            statusText: res.statusText,
            xhr
          });
        }
      };
      xhr.addEventListener('readystatechange', function() {
        console.log('xhr_listeners readystatechange1: ', 'readyState =', this.readyState)
      });
      xhr.addEventListener('readystatechange', function() {
        console.log('xhr_listeners readystatechange2: ', 'readyState =', this.readyState)
      });
      xhr.addEventListener('loadstart', function() {
        console.log('xhr_listeners loadstart2: ', 'readyState =', this.readyState)
      });
      xhr.addEventListener('loadend', function() {
        console.log('xhr_listeners loadend2: ', 'readyState =', this.readyState)
      });
      xhr.addEventListener('error', function() {
        console.log("xhr_listeners error", this)
        reject({
          status: this.status,
          statusText: this.statusText
        });
      });
      
      xhr.setRequestHeader('Content-Type', 'application/json');
      const body = (options.method !== 'GET' && !options.data) ? null : JSON.stringify(options.data);
      xhr.send(body);
    });
  } else if (apiType === 'ajax_api') {
    return new Promise((resolve, reject) => {
      const handleResponse = (res) => {
        console.log("ajax_api handleResponse:", res)
        isHtmlContent ? resolve({data: res.responseText}) : resolve({data: JSON.parse(res.responseText)});
      }
      const handleFailure = (res) => {
        console.log("ajax_api handleFailure:", res)
        reject();
      }
      AjaxAPI.sendRequest({ URL:url, ONSUCCESSFUNC: handleResponse, ONFAILUREFUNC: handleFailure, METHOD: options.method });
    });
  }
}

export default apiCall;
