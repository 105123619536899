import React, {useEffect, useRef} from 'react';
import Frame from 'react-frame-component';
import NewWindow from 'react-new-window';
import styled from 'styled-components';

const IFrame = () => {
  const divRef = useRef(null);

  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div ref={divRef}>
      {/* <NewWindow> */}
        <NewFrame>
           <div>Test iframe</div>
        </NewFrame>
      {/* </NewWindow> */}
    </div>
  )
}

export default IFrame;

const NewFrame = styled(Frame)`
  width: 50%;
  background-color: lightgrey;
`;