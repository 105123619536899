import { useState, useContext, useRef } from "react";
import styled from "styled-components";
import { Context } from "./util/context.js";
import Overview from "./overview/Overview.jsx";
import QuestionsList from "./QA/QuestionsList.jsx";
import ReviewsOverview from './R&R/Overview.jsx';
import RelatedProducts from './RelatedItems/Main.jsx';

import {ThemeProvider} from "styled-components";
import { GlobalStyles } from "./DarkMode/GlobalStyles.jsx";
import Toggle from "./DarkMode/Toggler.jsx";
import apiCall from "./util/apiCall.js";
import { useEffect } from "react";

const StyledApp = styled.div`
  max-width: 80%;
  margin: 0 auto;
  min-height: 100vh;
`;

const TopDiv = styled.div`
height: 4em;
width: 100%;
`;

const Product= ({id, setId, themeMode, themeToggler, theme , cart, setCart, cartQty, setCartQty, reviewsRef, scrollRef}) => {
  const { requestApi } = useContext(Context);
  const [productName, setProductName] = useState('');
  const syncContentRef = useRef();

  useEffect( () => {
    apiCall(requestApi, `/products/${id}`, { method: 'GET' })
    .then((res) => setProductName(res.data.name))
    .catch((err) => console.log(err));
  }, [requestApi]);

  const handleGetHtml = () => {
    apiCall(requestApi, 'html_content', { method: 'GET' })
    .then(res => {
      console.log('got html content', res.data)
      document.getElementById('html_injection_div').innerHTML = res.data;
    })
  }

  const handleGetSyncContent = () => {
    apiCall(requestApi, 'sync_content', { method: 'GET' })
    .then(res => {
      const node = document.createRange().createContextualFragment(res.data);
      syncContentRef.current.appendChild(node);
    })
  }

return (
  <ThemeProvider theme={themeMode}>
    <GlobalStyles/>
    <TopDiv ref={scrollRef}>
    </TopDiv>
    <StyledApp>
    <button onClick={handleGetSyncContent}>Get synchronous content</button>
    <br />
    <button onClick={handleGetHtml}>Get HTML</button>
    <div id='html_injection_div' />
    <div id='html_sync_injection_div' ref={syncContentRef}>
    </div>
      <Toggle theme={theme} toggleTheme={themeToggler} />
      <div>
        <Context.Provider value={{ id: id, cart, setCart, cartQty, setCartQty, requestApi }}>
          <Overview reviewsRef={reviewsRef} scrollRef={scrollRef}></Overview>
        </Context.Provider>
      </div>
      <div>
        <Context.Provider value={{id: id, setId, requestApi}}>
          <RelatedProducts scrollRef={scrollRef} ></RelatedProducts>
        </Context.Provider>
      </div>
      <div>
        <Context.Provider value={{id: id, requestApi}}>
          <QuestionsList productName={productName}></QuestionsList>
        </Context.Provider>
      </div>
      <div>
        <Context.Provider value={{id: id, productName: productName, requestApi}}>
          <ReviewsOverview reviewsRef={reviewsRef}></ReviewsOverview>
        </Context.Provider>
      </div>
    </StyledApp>
  </ThemeProvider>
);
}

export default Product;
