import { useState, useEffect, useContext } from 'react';
import { Context } from '../util/context.js';
import Reviews from "./Reviews.jsx";
import Ratings from './Ratings.jsx'
import styled from 'styled-components';
import apiCall from '../util/apiCall.js';

const Container = styled.div`
display: inline-block;
width: 100%;
@media (max-width: 768px) {
  flex-direction: column;
  display: flex;
}
`;

let ReviewsOverview = (props) => {
  const id = useContext(Context).id;
  const { requestApi } = useContext(Context);
  const [reviews, setReviews] = useState([]);
  const [selectValue, setSelectValue] = useState('relevant');
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [displayCount, setDisplayCount] = useState(2);

  // gets all arrays, by sort, 5 at a time by page
  const getSorted = () => {
    setLoading(true);
    apiCall(requestApi, `/reviews/?product_id=${id}&page=${page}&sort=${selectValue}`, { method: 'GET' })
    .then((result) => {
      let arr = result.data.results;
      if (Object.keys(filters).length !== 0) {
        arr = arr.filter((each) => (filters[each.rating]));
      }
      setReviews([...reviews, ...arr]);
      setCount(count + arr.length);
      setLoading(false);
    })
    .catch((err) => console.error('getSorted error', err))
    .finally(() => console.log(`getSorted finally`));
  }

  useEffect(() => {
      getSorted();
  }, [filters, page, selectValue, id, requestApi]);


  return (
    <Container ref={props.reviewsRef}>
      <Ratings
        setReviews={setReviews}
        reviews={reviews}
        setCount={setCount}
        filters={filters}
        setFilters={setFilters}
        setPage={setPage}
        setDisplayCount={setDisplayCount}
        >
      </Ratings>
      <Reviews
        reviews={reviews}
        setReviews={setReviews}
        selectValue={selectValue}
        getSorted={getSorted}
        setPage={setPage}
        count={count}
        setCount={setCount}
        setSelectValue={setSelectValue}
        loading={loading}
        setDisplayCount={setDisplayCount}
        displayCount={displayCount}
        filters={filters}>
      </Reviews>
    </Container>
  )
}

export default ReviewsOverview;
